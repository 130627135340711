<template>
      <div class="page-content-main">
          <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" class="margin-top">
            <el-form-item label="顶部图片" prop="lianlian_head_img">
              <head-img-upload v-model="formData.lianlian_head_img"></head-img-upload>
            </el-form-item>
              <el-form-item label="产业联链介绍" prop="lianlian_content">
                <el-input type="textarea" :rows="6" v-model="formData.lianlian_content"></el-input>
              </el-form-item>
              <el-form-item>
                  <el-button type="primary" @click="submitForm('form')">确定</el-button>
                  <el-button type="" @click="$router.back()">返回</el-button>
              </el-form-item>
          </el-form>
      </div>
</template>

<script>
    export default {
        name: "add",
        data() {
            return {
                formData: {
                  lianlian_content:'',
                  lianlian_head_img:''
                },
                rules: {
                  lianlian_content:[{required:true,message:'介绍内容必填'}],
                },
            };
        },
        mounted(){
            this.getDetail();
        },
        methods: {
            submitForm(formName) {
                let _this = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
	                    _this.$http.request({
		                    url:'/SystemSetting/edit',
		                    datas:_this.formData,
		                    success:(res)=>{
		                    	if(res === 'ok'){
				                    _this.$message.success('修改成功');
                                }else{
				                    _this.$message.error('修改失败');
                                }
		                    	// _this.$message.info(res.msg);
			                    // _this.$router.replace({path: '/cate/list'})
		                    }
	                    });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
	        getDetail() {
		        let _this = this;

		        let _skeys = [];

		        for(let k in this.formData){
			        _skeys.push(k);
                }

		        _this.$http.request({
			        url:'/SystemSetting/getSystem',
			        datas:{skey:_skeys},
			        success:(res)=>{
			        	_this.formData = res;
			        }
		        });
	        }
        }
    }
</script>
